import React from 'react'
import styled from 'styled-components'

import { Container, Text } from 'common/UI'
import { StepsStoryblok } from 'common/types'
import { textByLine } from 'common/utils/content'
import { dashedLine } from 'common/utils/style'
import { CMSRichText } from 'modules/shared'

import { Editable } from './Editable'

type Props = {
  block: StepsStoryblok
}

export const Steps = ({ block, ...props }: Props): JSX.Element => {
  const { steps, title, description } = block
  return (
    <StyledContainer variant="extra-narrow" {...props}>
      {(title || description) && (
        <div css={{ padding: '0 2rem' }}>
          {title && (
            <Text as="h2" variant="title">
              {title}
            </Text>
          )}
          {description && (
            <Text
              as="h3"
              variant="twenty"
              css={{ marginTop: title ? '1rem' : 0 }}
            >
              {textByLine(description, (part, i, length) => {
                return (
                  <>
                    {part}
                    {i + 1 < length && <br />}
                  </>
                )
              })}
            </Text>
          )}
        </div>
      )}
      <div css={{ position: 'relative', marginTop: '5rem' }}>
        <Line />
        {steps?.map((step) => (
          <Editable key={step._uid} block={step}>
            <StepWrapper>
              <div css={{ padding: '0.5rem' }}>
                <div css={{ position: 'relative' }}>
                  <Dot />
                  <Text as="h3" variant="title/small/regular">
                    {step.title}
                  </Text>
                </div>
                {step?.description && (
                  <CMSRichText
                    css={{ paddingTop: '2rem' }}
                    richtext={step?.description}
                  />
                )}
              </div>
            </StepWrapper>
          </Editable>
        ))}
      </div>
    </StyledContainer>
  )
}

const Line = styled.div`
  position: absolute;
  align-self: flex-end;
  width: 1px;
  bottom: 0;
  height: calc(100% - 1rem);
  ${({ theme }) => dashedLine('left', theme.colors.foreground.subtle)}
`

const StepWrapper = styled.div`
  position: relative;
  padding-left: 2rem;
  padding-bottom: 5rem;
`

const StyledContainer = styled(Container)`
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;

  ${StepWrapper}:first-child ${Line} {
    height: calc(100% - 1rem);
  }

  ${({ theme }) => theme.media.md} {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
`

const Dot = styled.div`
  position: absolute;
  left: -2.85rem;
  margin: auto;
  top: 0;
  bottom: 0;
  border-radius: 50%;
  height: 0.8rem;
  width: 0.8rem;
  background: ${({ theme }) => theme.colors.accent.subtle};
`
